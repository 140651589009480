import { factory } from 'mathjs';
import { JSON_FIELD_VALUE_SEPARATOR_PATTERN } from '../components/procedure/constants';

const isNullish = v => {

  if( v === undefined ) {
    return true;
  }

  if( v === null ) {
    return true;
  }

  return false;

}

const isEmptyFieldValue = (fieldDefinition, rawValue) => {

  // first, follow utility.js isEmpty()

  if( isNullish(rawValue) ) {
    return true;
  }

  if( typeof ( rawValue ) === 'string' && rawValue.trim() === '' ) {
    return true;
  }

  if( typeof( rawValue ) === 'object'
      && Object.keys( rawValue ).length === 0 ) {
    return true;
  }

  if( Array.isArray( rawValue ) && rawValue.length === 0 ) {
    return true;
  }

  // then follow state.js isEmptyMath

  if( rawValue === '{"value":""}' ) {
    return true;
  }

  if (fieldDefinition?.other
      && rawValue.split(JSON_FIELD_VALUE_SEPARATOR_PATTERN).map(f => JSON.parse(f))
        .some(i => i?.value === '' || i?.other === '')) {
      return true;
  }

  return false;
};

export const createIsEmpty = factory('isEmpty', [], () => {
  return context => {

    const { fieldDefinition, fieldData } = context;
    const rawValue = fieldData?.data?.[0]?.value;

    return isEmptyFieldValue(fieldDefinition, rawValue);
  };
});

export const createIsComplete = factory('isComplete', [], () => {
  return context => {

    const { fieldDefinition, fieldData } = context;
    const rawValue = fieldData?.data?.[0]?.value;

    return !isEmptyFieldValue(fieldDefinition, rawValue);
  };
});

// pick-one
const isFieldValueElementInSet = (fieldDefinition,
                                  v, rawValueSet, rawOtherValueSet) => {

  const valueSet = rawValueSet.split("|");
  if( !valueSet.includes( v.value ) ) {
    return false;
  }

  // assert valueSet.includes( v.value )

  if( isNullish( rawOtherValueSet )) {
      return true;
  }

  // assert valueSet.includes( v.value ) and !isNullish( rawOtherValueSet )

  if ( isNullish( v.other )) {
    return false;
  }

  const otherValueSet = rawOtherValueSet.split('|');
  return otherValueSet.includes( v.other );

};

const isFieldValueInSet = (fieldDefinition,
                           rawValue, valueSet, otherValueSet) => {

  // check v is non-empty before getting here
  if ( isEmptyFieldValue( fieldDefinition, rawValue )) {
    throw new Error( 'field value is empty' );
  }

  switch (fieldDefinition.type) {
    case 'pick-one': {
      const value = JSON.parse( rawValue );
      return isFieldValueElementInSet(fieldDefinition,
                                      value, valueSet, otherValueSet);
    }
    case 'pick-many': {
      const values = rawValue.split(JSON_FIELD_VALUE_SEPARATOR_PATTERN).map(f => JSON.parse(f) );
      return values.some(value => isFieldValueElementInSet(fieldDefinition,
                                      value, valueSet, otherValueSet));
    }
    default:
      return valueSet.includes( rawValue );
  }
};

export const createInValue = factory('inValue', [], () => {
  return (context, valueSet, otherValueSet) => {

    const { fieldDefinition, fieldData } = context;
    const rawValue = fieldData?.data?.[0]?.value;

    if ( isEmptyFieldValue( fieldDefinition, rawValue )) {
      return false;
    }

    return isFieldValueInSet( fieldDefinition,
                              rawValue, valueSet, otherValueSet );
  };
});

export const createNotInValue = factory('notInValue', [], () => {
  return (context, valueSet, otherValueSet) => {

    const { fieldDefinition, fieldData } = context;
    const rawValue = fieldData?.data?.[0]?.value;

    if ( isEmptyFieldValue( fieldDefinition, rawValue )) {
      return false;
    }

    return !isFieldValueInSet( fieldDefinition,
                               rawValue, valueSet, otherValueSet );
  };
});

