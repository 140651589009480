import { useState } from 'react';

import {
  Button,
  Space,
  Input,
  Tooltip,
  Form,
  Drawer,
  Modal,
  Badge,
} from 'antd';

import { useDataUpdate } from '../hook/useDataUpdate';
import {
  findQueryRole,
  protocolUserRoleFromUserData,
} from '../lib/roleUtils';
import { indicateSelectedFieldForQueryDetail } from '../legacy/LegacyFacade';
import {
  fieldTextFromProtocolDefinitionFromData,
  isEdcFromProtocolData,

  protocolDefinitionFromData,
  procedureDefinitionFromProtocolDefinition,
  fieldDefinitionOrGridFromProcedureDefinition,
} from '../lib/protocolDefinitionUtils';
import { getAppropriateProtocolInstance } from '../lib/entityUtils';

import {
  Query,
  FormDecoratedQuery,
} from './DataItemQueries';

import { HoverWrapperForClassicEnvironment } from './HoverWrapperForClassicEnvironment';
import './FieldActionsDisplay.scss';
import FieldActionsButtons from './FieldActionsButtons';
import { useVisitProcedureTextFieldLimiters } from '../hook/useVisitProcedureTextFieldLimiters';

const NewQueryDialog = props => {
  const {
    userData,
    context,
    handleFormCancel,
    setIsQueryDisplayVisible,
    setIsFormHasValues,
  } = props;

  const [ form ] = Form.useForm();
  const [ isSubmitButtonActive, setIsSubmitButtonActive ] = useState(false);

  const queryAdd = useDataUpdate({

    endpoint: 'gui/queryAdd',
    invalidates: [ 'gui/queryList', 'gui/queryCountList' ],

  });

  const onValuesChange = (changedValues, allValues) => {

    if(allValues?.['message-text']
       && allValues?.['message-text'].trim().length !== 0){
      setIsFormHasValues(true);
      return;
    }

    setIsFormHasValues(false);
  }
  const onFieldsChange = (changedFields, allFields) => {

    const messageTextField= allFields.find(
      f => f.name[0] === 'message-text');

    if( messageTextField
       && messageTextField?.value.trim().length !== 0){
      setIsFormHasValues(true);
      return;
    }

    setIsFormHasValues(false);
  }

  const onFinish = values => {

    const sendData = async (values) => {
      await queryAdd.mutateAsync(
        {
          data: {
            date: values.date,
            status: values.action,
            thread: [
              {
                date: values.date,
                action: values.action,
                content: values['message-text'],
                author: values.author,
              },
            ],
            context,
          },
        },
        {
          onSuccess: (data, variables, context) => {
            form.resetFields();
            setIsQueryDisplayVisible(false);
          },
        }
      );
    };

    sendData(values);
  };

  const onMessageChange = e => {
    const value = e.target.value;
    setIsSubmitButtonActive(value.length > 0);
  };

  const author = {
    email: userData.user.email,
    firstName: userData.user.first_name,
    lastName: userData.user.last_name,
    affiliation: userData.user.org,
    role: protocolUserRoleFromUserData(userData, context.protocolId),
  };

  const date = Date.now();

  return (
      <Form
        className='NewQueryDialog'
        onFinish={onFinish}
        form={form}
        initialValues={{
          'message-text': '',
          action: 'open',
          author,
          date,
        }}
        onFieldsChange={onFieldsChange}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name='message-text'
          rules={[{ required: true }]}
          className='textarea-box'
        >
          <Input.TextArea
            onChange={onMessageChange}
            placeholder='message'
          ></Input.TextArea>
        </Form.Item>
        <Form.Item name='author' hidden={true}>
          <span />
        </Form.Item>
        <Form.Item name='date' hidden={true}>
          <span />
        </Form.Item>
        <Form.Item name='action' hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item className='buttons-box'>
          <Space>
            <Button
            className='EdcNewQueryFormButton'
             onClick={() => handleFormCancel(form)}>Cancel</Button>
            <Button
              className='EdcNewQueryFormButton'
              htmlType='submit'
              disabled={!isSubmitButtonActive}
            >
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
  );
};

export const FieldActionsDisplay = props => {

  const {
    userData,

    context,

    isQueryDisplayVisible,
    isQueryListSelected,
    isReadOnly = false,

    handleFormCancel,
    setIsNewQueryFormHasValues,
    setIsQueryDisplayVisible,

    procedureQueryItems,

    renderClassicProcedureView,
  } = props;

  if (!isQueryDisplayVisible) {
    return null;
  }

  const fieldQueryItems = procedureQueryItems.filter(
    item => item.context?.fieldId === context?.fieldId);

  if(isReadOnly && !isQueryListSelected) {
    // in read-only mode there is no new query form
    return null;
  }

  if(isReadOnly && fieldQueryItems.length === 0) {
    return null;
  }

  const principalQueryRole = findQueryRole(
    protocolUserRoleFromUserData( userData, context.protocolId ));

  return (
    <>
      {!isReadOnly && !isQueryListSelected && (
        <NewQueryDialog {...{
          userData,
          context,
          handleFormCancel,
          setIsQueryDisplayVisible,
          setIsFormHasValues: setIsNewQueryFormHasValues,
        }}/>
      )}

      { isQueryListSelected && (
        <div className='QueryList'>
        { fieldQueryItems.map(queryItem => {

          if(isReadOnly) {
            return (
              <Query
                key={queryItem.etag}
                queryItem={queryItem}
                isExpanded={
                     (principalQueryRole === 'initiator'
                       && queryItem.status === 'answered')
                  || (principalQueryRole !== 'initiator'
                       && queryItem.status === 'open')
                }
              />
            );
          }

          return (

            <FormDecoratedQuery
              context={context}
              key={queryItem.etag}
              userData={userData}
              queryItem={queryItem}
              renderClassicProcedureView={renderClassicProcedureView}
            />
          );

        })}
        </div>
      )}
    </>
  );
};

const VisitProcedureFieldQueriesDrawer = props => {

  const {
    userData,

    context,
    protocolData,
    siteInfo,
    visitInfo,
    procedureInfo,

    fieldDefinitionId,

    isQueryDisplayVisible,
    isDisplayHasActiveQueries,
    procedureQueryItems,
    isQueriesAreReadOnly,

    setIsQueryDisplayVisible,

    renderClassicProcedureView,
  } = props;

  const [ isModalVisible, setIsModalVisible ] = useState( false );
  const [ isNewQueryFormHasValues,
       setIsNewQueryFormHasValues ] = useState( false );

  const principalQueryRole = findQueryRole(
    protocolUserRoleFromUserData( userData, context.protocolId ));

  const [ isQueryListSelected, setIsQueryListSelected ]
            = useState( principalQueryRole !== 'initiator'
                        || isDisplayHasActiveQueries
                        || isQueriesAreReadOnly
                      );

  if(!isQueryDisplayVisible) {
    return null;
  }

  const checkIfFormHasValues = form => {
    const values = form?.getFieldsValue();
    if(values?.['message-text'] && values?.['message-text'].trim().length !== 0){
      return true;
    }
    return false;
  }

  const onClose = () => {

    if(isNewQueryFormHasValues){
      setIsModalVisible(true);
      return;
    }

    setIsQueryDisplayVisible( false );
  };

  const handleFormCancel = form => {

    if(checkIfFormHasValues(form)) {
      setIsModalVisible( true );
      return;
    }
    setIsQueryDisplayVisible(false);
  };

  // dialog: abandon partially filled form?
  const handleOk = () => {
    setIsQueryDisplayVisible(false);
    setIsModalVisible(false);
  };

  // dialog: abandon partially filled form?
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const siteProtocolBranchObject = siteInfo?.protocolBranches.find(branch => branch.branch === context.protocolVersionName);
  const protocolInstance = getAppropriateProtocolInstance(visitInfo, 'visit', siteProtocolBranchObject);

  const queryDisplayLabel = fieldTextFromProtocolDefinitionFromData(
                      protocolData,
                      siteInfo.protocol_id,
                      context.protocolVersionName,
                      procedureInfo.procedureId,
                      fieldDefinitionId,
                      protocolInstance,
                      );

  const toggleView = () => {
    setIsQueryListSelected( !isQueryListSelected )
  };

  const titleComponent = (<>
      <Tooltip className='EdcNewQueryFormTitle'
               placement='bottom'
               title={queryDisplayLabel}
               overlayClassName='EdcNewQueryFormTitleTooltip'
               >
        {queryDisplayLabel}
      </Tooltip>
      </>);

  const fieldQueryItems = procedureQueryItems?.filter(
    item => item?.context?.fieldId === context.fieldId );

  const extraComponent =
      principalQueryRole === 'initiator'
      && !isQueriesAreReadOnly
      ? (
        <Button
          className='EdcNewQueryViewQueriesButton'
          type='link'
          onClick={toggleView}
          disabled={ fieldQueryItems.length === 0 }
        >
          {isQueryListSelected ? 'New Query'
                               : isDisplayHasActiveQueries
                                 ? 'View Queries'
                                 : fieldQueryItems.length > 0
                                   ? 'View Closed Queries'
                                   : 'No Previous Queries'
          }
        </Button>
      )
      : null;

  return <>
    <Drawer
      className='VisitProcedureFieldQueriesDrawer'
      destroyOnClose={true}
      closable={true}
      visible={true}
      onClose={onClose}
      title={titleComponent}
      extra={extraComponent}
    >
      <FieldActionsDisplay {...{
        userData,

        context,

        procedureQueryItems,

        isQueryDisplayVisible,
        isQueryListSelected,
        isReadOnly: isQueriesAreReadOnly,

        handleFormCancel,
        setIsNewQueryFormHasValues,
        setIsQueryDisplayVisible,

        renderClassicProcedureView,

      }} />
    </Drawer>
    {isModalVisible && (
      <Modal  title='Leave Draft?'
        destroyOnClose={true}
        visible={true}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Your draft will not be saved.</p>
      </Modal>
    )}
  </>;
};


const FieldActionsDisplayControl = props => {

  // this is the thing that's being attached to every field

  const {
    userData,

    protocolData,
    siteInfo,
    visitInfo,
    procedureInfo,
    fieldId,

    fieldDefinitionId,
    isFieldReviewEnabled,

    procedureQueryItems,
    fieldReviewItems,
    isVisitLocked,

    isQueryDisplayVisible,
    setIsQueryDisplayVisible,

    renderClassicProcedureView,
    visitIdChar,
    isSplitView,
  } = props;
  useVisitProcedureTextFieldLimiters(protocolData);

  const protocolVersionName = visitInfo?.data?.visit?.protocol_branch;
  const protocolId = siteInfo.protocol_id;

  const context = {
    protocolId,
    protocolVersionName,
    siteId: siteInfo?.siteId,
    subjectId: visitInfo?.data?.visit?.subject_id,
    visitId: visitInfo?.data?.visit?.visit_instance_id,
    procedureId: procedureInfo?.procedureId,
    procedureRepeatIndex: procedureInfo?.repeatIndex,
    fieldId,
    visitIdChar,
  };

  const fieldQueries = procedureQueryItems?.filter(
    item => item?.context?.fieldId === fieldId
  );


  const fieldReview = fieldReviewItems?.find(
    item => item?.context?.fieldId === fieldId
  );

  const openQueriesCount = fieldQueries.filter(
                             item => item.status === 'open'
                           ).length;
  const answeredQueriesCount = fieldQueries.filter(
                                 item => item.status === 'answered'
                               ).length;
  const closedQueriesCount = fieldQueries.filter(
                               (item) => item.status === "closed"
                             ).length;

  const protocolUserRole = protocolUserRoleFromUserData( userData, protocolId );

  const principalQueryRole = findQueryRole(protocolUserRole);

  const setSelectionState = isSelected => {
    indicateSelectedFieldForQueryDetail(
                                fieldDefinitionId, isSelected);
    setIsQueryDisplayVisible( () => isSelected );
  };

  const toggleDrawer = () => {
    setSelectionState(!isQueryDisplayVisible);
  }

  return ( <>
    <HoverWrapperForClassicEnvironment
      context={context}
      userData={userData}
      isQueryDisplayVisible={isQueryDisplayVisible}
      openQueriesCount={openQueriesCount}
      answeredQueriesCount={answeredQueriesCount}
      closedQueriesCount={closedQueriesCount}
      isFieldReviewEnabled={isFieldReviewEnabled}
      fieldReview={fieldReview}
      fieldDefinitionId={fieldDefinitionId}
      toggleDrawer={toggleDrawer}
      principalQueryRole={principalQueryRole}
      protocolUserRole={protocolUserRole}
      isQueriesAreReadOnly={isVisitLocked || principalQueryRole === null}
      isSplitView={isSplitView}
      renderHoverableComponent={ (childProps, isActive) => {
        return (
          <FieldActionsButtons
            isActive={isActive}
            {...childProps}
          />
        );
      }}
    />
      <VisitProcedureFieldQueriesDrawer {...{
        userData,

        context,
        protocolData,
        siteInfo,
        visitInfo,
        procedureInfo,

        fieldDefinitionId,

        isQueryDisplayVisible,
        isDisplayHasActiveQueries: (openQueriesCount > 0
                                 || answeredQueriesCount > 0),
        procedureQueryItems,
        isQueriesAreReadOnly: isVisitLocked || principalQueryRole === null,

        setIsQueryDisplayVisible: setSelectionState,

        renderClassicProcedureView,
      }}/>

  </>);
};

const FieldActionsControl = props => {

  const {

    portalUtils,
    userData,

    protocolData,
    siteInfo,
    visitInfo,
    procedureInfo,
    procedureDefinition,
    fieldId,

    procedureQueryItems,
    fieldReviewItems,
    isVisitLocked,

    renderClassicProcedureView,
    visitIdChar,
    isSplitView,
    } = props;

  const [isQueryDisplayVisible, setIsQueryDisplayVisible] =
                                                              useState(false);

  const fieldDefinitionFromVisitInfo = visitInfo.data.fields
                .find( f => fieldId === f.field_instance_id);

  // eg ref: {
  //  field_id: '3eed31f4-4d45-11e7-b114-b2f933d5fe66',
  //  measurement_id: 'SYSBP',
  //  type: 'field_id'
  // }
  // we may have to deal with other types than field_id

  const fieldDefinitionId = fieldDefinitionFromVisitInfo?.ref?.field_id
                            ?? fieldDefinitionFromVisitInfo.field_id;

  if('grid' in fieldDefinitionFromVisitInfo && fieldDefinitionFromVisitInfo.grid.col !== 0) {
    return null;
  }

  const fieldDefinition = fieldDefinitionOrGridFromProcedureDefinition(
    procedureDefinition, fieldDefinitionId);

  const isFieldReviewEnabled = fieldDefinition?.reviewable;

  return portalUtils.attachFieldControls(
    {
      procedureId: procedureInfo.procedureId,
      procedureRepeatIndex: procedureInfo.repeatIndex,
      fieldId,
    },
    <FieldActionsDisplayControl

      userData={userData}

      protocolData={protocolData}
      siteInfo={siteInfo}
      visitInfo={visitInfo}
      procedureInfo={procedureInfo}
      fieldId={fieldId}

      procedureQueryItems={procedureQueryItems}
      fieldReviewItems={fieldReviewItems}
      isVisitLocked={isVisitLocked}

      fieldDefinitionId={fieldDefinitionId}
      isFieldReviewEnabled={isFieldReviewEnabled}

      isQueryDisplayVisible={isQueryDisplayVisible}
      setIsQueryDisplayVisible={setIsQueryDisplayVisible}

      renderClassicProcedureView={renderClassicProcedureView}
      visitIdChar={visitIdChar}
      isSplitView={isSplitView}
    />
  );
};

export const FieldActionsControls = props => {

  const {
    portalUtils,
    userData,

    protocolData,
    siteInfo,
    visitInfo,
    procedureInfo,

    procedureQueryItems,
    fieldReviewItems,
    isVisitLocked,

    renderClassicProcedureView,
    visitIdChar,
    isSplitView,
  } = props;

  if (!procedureInfo?.procedureId) {
    return null;
  }

  if (!visitInfo?.data?.visit?.procedures) {
    return null;
  }

  const protocolVersionName = visitInfo.data.visit.protocol_branch;
  const protocolDefinition = protocolDefinitionFromData(
      protocolData,
      siteInfo.protocol_id,
      protocolVersionName,
    );
  const procedureDefinition = procedureDefinitionFromProtocolDefinition(
      protocolDefinition, procedureInfo.procedureId
    );

  if(!procedureDefinition.dataentry?.regular) {
    return;
  }

  const dataEntryFieldDefinitionIds = procedureDefinition.dataentry.regular
          .flatMap( i => {
            if(i.type === 'grid') {
              return i.row.map(c => c.id);
            }
            return i.id;
          });

  const dataEntryFieldIds = [];

  for (const fieldDefinitionId of dataEntryFieldDefinitionIds) {

    const fields = visitInfo.data.fields.filter( f =>
      fieldDefinitionId === f.field_id
      && f?.repeat_label_idx?.toString()
          === procedureInfo?.repeatIndex?.toString()
    );

    dataEntryFieldIds.push(...fields.map(f => f.field_instance_id));

  }

  return (
    <>
    {
      dataEntryFieldIds.map(fieldId => (
        <FieldActionsControl
          key={`${procedureInfo.procedureId
              }-${procedureInfo.repeatIndex
              }-${fieldId}`}
          portalUtils={portalUtils}
          userData={userData}

          protocolData={protocolData}
          siteInfo={siteInfo}
          visitInfo={visitInfo}
          procedureInfo={procedureInfo}
          procedureDefinition={procedureDefinition}
          fieldId={fieldId}

          procedureQueryItems={procedureQueryItems}
          fieldReviewItems={fieldReviewItems}
          isVisitLocked={isVisitLocked}

          renderClassicProcedureView={renderClassicProcedureView}
          visitIdChar={visitIdChar}
          isSplitView={isSplitView}
        />
      ))}
    </>
  );
};

const VisitProcedureQueriesDrawer = props => {

  const {
    userData,

    context,
    protocolData,
    siteInfo,
    visitInfo,
    procedureInfo,

    procedureQueryItems,
    isQueryDisplayVisible,
    isDisplayHasActiveQueries,
    isQueriesAreReadOnly,

    setIsQueryDisplayVisible,

    renderClassicProcedureView,
  } = props;

  const [ isModalVisible, setIsModalVisible ] = useState(false);
  const [ isNewQueryFormHasValues, setIsNewQueryFormHasValues ] = useState(false);

  const principalQueryRole = findQueryRole(
    protocolUserRoleFromUserData( userData, context.protocolId ));

  const [ isQueryListSelected, setIsQueryListSelected ]
            = useState( principalQueryRole !== 'initiator'
                        || isDisplayHasActiveQueries);

  if(!isQueryDisplayVisible) {
    return null;
  }

  const checkIfFormHasValues = form => {
    const values = form?.getFieldsValue();
    if(values?.['message-text'] && values?.['message-text'].trim().length !== 0){
      return true;
    }
    return false;
  }

  const onClose = () => {
    if(isNewQueryFormHasValues){
      setIsModalVisible(true);
      return;
    }
    setIsQueryDisplayVisible( false );
  };

  const handleFormCancel = form => {

    if(checkIfFormHasValues(form)){
      setIsModalVisible(true);
      return;
    }
    setIsQueryDisplayVisible(false);
  };

  // dialog: abandon partially filled form?
  const handleOk = () => {
    setIsQueryDisplayVisible(false);
    setIsModalVisible(false);
  };

  // dialog: abandon partially filled form?
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const siteProtocolBranchObject = siteInfo?.protocolBranches.find(branch => branch.branch === context.protocolVersionName);
  const protocolInstance = getAppropriateProtocolInstance(visitInfo, 'visit', siteProtocolBranchObject);

  const queryDisplayLabel = fieldTextFromProtocolDefinitionFromData(
                      protocolData,
                      siteInfo.protocol_id,
                      context.protocolVersionName,
                      procedureInfo.procedureId,
                      null, //fieldDefinitionId,
                      protocolInstance,
                      );

  const toggleView = () => {
    setIsQueryListSelected( !isQueryListSelected )
  };

  const titleComponent = (<>
      <Tooltip className='EdcNewQueryFormTitle'
               placement='bottom'
               title={queryDisplayLabel}
               overlayClassName='EdcNewQueryFormTitleTooltip'
               >
        {queryDisplayLabel}
      </Tooltip>
      </>);

  const procedureLevelQueryItems = procedureQueryItems?.filter(
    item => !Boolean( item?.context?.fieldId ));

  const extraComponent =
      !isQueriesAreReadOnly
      && principalQueryRole === 'initiator'
      ? (
        <Button
          className='EdcNewQueryViewQueriesButton'
          type='link'
          onClick={toggleView}
          disabled={ procedureLevelQueryItems.length === 0 }
        >
          {isQueryListSelected ? 'New Query'
                               : isDisplayHasActiveQueries
                                 ? 'View Queries'
                                 : procedureLevelQueryItems.length > 0
                                   ? 'View Closed Queries'
                                   : 'No Previous Queries'
          }
        </Button>
      )
      : null;

  return <>
      <Drawer
        className='VisitProcedureQueriesDrawer'
        destroyOnClose={true}
        closable={true}
        visible={isQueryDisplayVisible}
        onClose={onClose}
        title={titleComponent}
        extra={extraComponent}
      >
        <FieldActionsDisplay {...{
          userData,

          context,

          procedureQueryItems,

          isQueryDisplayVisible,
          isQueryListSelected,
          isReadOnly: isQueriesAreReadOnly,

          handleFormCancel,
          setIsNewQueryFormHasValues,
          setIsQueryDisplayVisible,

          renderClassicProcedureView,
        }} />
      </Drawer>
      {isModalVisible && <Modal  title='Leave Draft?'
        destroyOnClose={true}
        visible={true}
        onOk={handleOk}
        onCancel={handleCancel}
        >
          <p>Your draft will not be saved.</p>
        </Modal>}
  </>;

};

export const ProcedureQueryDisplayControl = props => {

  const {
    userData,

    protocolData,
    siteInfo,
    visitInfo,
    procedureInfo,

    procedureQueryItems,
    isVisitLocked,

    renderClassicProcedureView,
  } = props;

  const [ isQueryDisplayVisible, setIsQueryDisplayVisible ] = useState(false);

  if( !siteInfo || !visitInfo || !procedureInfo || !userData ) {
    return null;
  }

  const context = {
    protocolId: siteInfo.protocol_id,
    protocolVersionName: visitInfo?.data?.visit?.protocol_branch,
    siteId: siteInfo?.siteId,
    subjectId: visitInfo?.data?.visit?.subject_id,
    visitId: visitInfo?.data?.visit?.visit_instance_id,
    procedureId: procedureInfo?.procedureId,
    procedureRepeatIndex: procedureInfo?.repeatIndex,
    protocolBranch: visitInfo?.data?.visit?.protocol_branch,
    protocolInstanceId: visitInfo?.data?.visit?.protocol_instance,
    visitIdChar: visitInfo?.id,
  };

  const fieldQueries = procedureQueryItems?.filter(
    item => !item?.context?.fieldId
  );

  const openQueriesCount = fieldQueries.filter(
                             item => item.status === 'open'
                           ).length;
  const answeredQueriesCount = fieldQueries.filter(
                                 item => item.status === 'answered'
                               ).length;

  const principalQueryRole = findQueryRole(
    protocolUserRoleFromUserData( userData, context.protocolId ));

  if( openQueriesCount + answeredQueriesCount === 0
      && ( isVisitLocked || principalQueryRole === null )) {
    return null;
  }

  return (
    <>
    {
      isEdcFromProtocolData(protocolData, siteInfo.protocolVersionName)
      && principalQueryRole === 'initiator'
      && !isVisitLocked
      && (
      <Button
        className='ProcedureQueryDisplayControlButton'
        onClick={() => setIsQueryDisplayVisible(!isQueryDisplayVisible)}
      >
        {openQueriesCount || answeredQueriesCount
          ? 'Form-Level Queries'
          : 'New Form-Level Query'}
        { openQueriesCount > 0 && (
          <Badge
            className='EdcPlusProcedureLevelQueryBadge'
            count={openQueriesCount}
            showZero={true}
            id={`procedure-open-badge`}
          />
        )}
        { answeredQueriesCount > 0 && (
          <Badge
            className='EdcPlusAnsweredProcedureLevelQueryBadge'
            count={answeredQueriesCount}
            showZero={true}
            id={`procedure-answered-badge`}
          />
        )}
      </Button>
      )}
      {
        openQueriesCount + answeredQueriesCount > 0
        && ( principalQueryRole !== 'initiator'
             || (
               principalQueryRole === 'initiator'
               && isVisitLocked
             )
           )

        && (
      <Button
        className='ProcedureQueryDisplayControlButton'
        onClick={() => setIsQueryDisplayVisible(!isQueryDisplayVisible)}
      >
        Form-Level Queries
        {
          openQueriesCount > 0 && (
          <Badge
            className='EdcPlusProcedureLevelQueryBadge'
            count={openQueriesCount}
            showZero={true}
            id={`procedure-open-badge`}
          />
        )}
        {
          answeredQueriesCount > 0 && (
          <Badge
            className='EdcPlusAnsweredProcedureLevelQueryBadge'
            count={answeredQueriesCount}
            id={`procedure-answered-badge`}
            showZero={true}
          />
        )}
      </Button>
      )}

      <VisitProcedureQueriesDrawer {...{
            userData,

            context,
            protocolData,
            siteInfo,
            visitInfo,
            procedureInfo,

            procedureQueryItems,

            isDisplayHasActiveQueries: (openQueriesCount > 0
                                 || answeredQueriesCount > 0),
            isQueryDisplayVisible,
            isQueriesAreReadOnly: isVisitLocked || principalQueryRole === null,
            setIsQueryDisplayVisible,

            renderClassicProcedureView,
      }}/>

    </>
  );
};
