import {
  countProcedureFields as countProcedureFieldsInternal
} from '@vessel/vessel-common';

import {
  protocolDefinitionFromData,
  procedureDefinitionFromProtocolDefinition,
} from './protocolDefinitionUtils';
import { getAppropriateProtocolInstance } from '../lib/entityUtils';

/**
 * Count the number of fields for a given procedure.
 * 
 * @param {Object} context - Application context, including the procedure id
 *                           and repeat index. 
 * @param {Object} protocolData - protocol data from the `protocol.get`
 *                                endpoint.
 * @param {Object} siteInfo - site info from a site selection action.
 * @param {Object} visitInfo - visit info from the `visit.load` endpoint.
 * @returns {Object} - The return value is an object with three properties,
 * all numbers: `total`, `complete`, and `missing`. `total` is the total
 * 'countable' field  count for the procedure, after applying the field
 * conditions. `complete` is the number of such fields that have data, and
 * `missing` is the number of countable fields that do not have data.
 */
export const countProcedureFields = ({
  context,
  protocolData,
  siteInfo,
  visitInfo,
}) => {

  const fieldDataItems = visitInfo.data.fields.filter(
    field => field.procedure_id === context.procedureId
           && field?.repeat_label_idx?.toString()
             === context?.procedureRepeatIndex?.toString()
  );
  const protocolVersionName = visitInfo?.data?.visit?.protocol_branch
    ?? siteInfo.protocolVersionName;

  const siteProtocolBranchObject = siteInfo?.protocolBranches.find(branch => branch.branch === protocolVersionName);
  const protocolInstance = getAppropriateProtocolInstance(visitInfo, 'visit', siteProtocolBranchObject);

  const protocolDefinition = protocolDefinitionFromData(
    protocolData,
    siteInfo.protocol_id,
    protocolVersionName,
    protocolInstance,
  );
  const procedureDefinition = procedureDefinitionFromProtocolDefinition(
    protocolDefinition, context.procedureId
  );

  const measurementDefinitions = protocolDefinition.study.measurement;

  return countProcedureFieldsInternal({
    fieldDataItems,
    procedureDefinition,
    measurementDefinitions,
    options: {
      isIgnoreFieldConditionsWhenAllDataIsEmpty: true,
    },
  });
  
};
